import * as React from 'react';
import styled from 'styled-components';

import { fromScreen } from '@doltech/core/lib/responsive/responsive.util';
import { ButtonSizableContent } from '../../../../../../Button/ButtonGeneral/ButtonSizableContent';
import { ButtonStyles } from '../../../../../../Button/ButtonStyles/ButtonStyles';
import { colorsV2 } from '../../../../../../colors-v2';
import { ResponsiveTypography } from '../../../../../../Typography/v3/ResponsiveTypography';
import { SectionPromotionCommonProps } from '../../type';

const Wrapper = styled.div`
  background-color: ${colorsV2.primary10};
  border-radius: 8px;
  padding: 16px;
  ${fromScreen(768)} {
    padding: 28px 40px;
  }
  ${fromScreen(1024)} {
    padding: 40px 68px;
  }
`;

const Content = styled.div`
  display: grid;
  justify-items: start;
  gap: 12px;

  ${fromScreen(768)} {
    grid-auto-flow: column;
    gap: 28px;

    justify-content: space-between;
    align-items: center;
  }
  ${fromScreen(1024)} {
    grid-template-columns: minmax(0, 691px) max-content;
    gap: 68px;
  }
`;

export const SectionPromotionSignupShort = (props: SectionPromotionCommonProps) => {
  const { actionTitle, description, title, actionOnClick } = props;
  return (
    <Wrapper>
      <Content>
        <div className="content">
          <ResponsiveTypography.Paragraph
            color="black100"
            breakpoints={[0, 768]}
            variant={['bold/20-28', 'bold/32-40']}
          >
            {title}
          </ResponsiveTypography.Paragraph>
          <ResponsiveTypography.Paragraph
            color="black80"
            mt="8px"
            breakpoints={[0, 768]}
            variant={['regular/14-20', 'regular/16-24']}
          >
            {description}
          </ResponsiveTypography.Paragraph>
        </div>
        <div className="action">
          <ButtonStyles
            buttonStyle="filled"
            color="primary"
            colorStyle="bold"
            onClick={actionOnClick}
            content={<ButtonSizableContent text={actionTitle} />}
          />
        </div>
      </Content>
    </Wrapper>
  );
};
